import React, { useEffect,useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import Sidebar from '../common/Sidebar';
import Navigation from '../common/Navigation';
import { Link } from 'react-router-dom';
import axios from 'axios';
const Getlocations = () => {
  const [ownerID, setOwnerId] = useState(null);
  const [coords, setCoords] = useState([]); 
  const [error, setError] = useState(null);
  const [list, setList] = useState([]); 

  const loader = new Loader({
    apiKey: "AIzaSyBdPwDzZPe6ISS8cuIIyxe9EcV9bq89xpU", 
    version: "weekly",
    libraries: ["maps", "places"],
  });
  useEffect(() => {
   
    const initMap = async () => {
      

      loader.load().then((google) => {
        const centerOfPolygon  = (coords) =>  {
          let latitudeSum = 0;
          let longitudeSum = 0;
        
          coords.forEach((point) => {
            latitudeSum += point.lat;
            longitudeSum += point.lng;
          });
      
          const center = {
            latitude: latitudeSum / coords.length,
            longitude: longitudeSum / coords.length,
          };
          return center;
        }
        let latLngCenter = centerOfPolygon(list[0][0]);
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 16,
          center: {  lat : latLngCenter.latitude, lng : latLngCenter.longitude },
          mapId: "1",
          
        });

        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });


        //loop for multiple plots 
        // start 
        
        list[0].forEach(element => {

        if(element.length > 0){
        

        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

         const markers = element.map((position, i) => {
          const label = labels[i % labels.length];
          
          // const pinGlyph = new google.maps.MarkerImage(
          //   `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${label}|FF0000|000000`
          // );
          // console.log(pinGlyph)
          const marker = new google.maps.Marker({
            position,
            // icon: pinGlyph,
            map,

          });
          return marker;
        });

        
        // Add a marker clusterer to manage the markers.

        const centerOfPolygon  = (coords) =>  {
          let latitudeSum = 0;
          let longitudeSum = 0;
        
          coords.forEach((point) => {
            latitudeSum += point.lat;
            longitudeSum += point.lng;
          });
      
          const center = {
            lat: latitudeSum / coords.length,
            lng: longitudeSum / coords.length,
          };
          return center;
        }
        const cntCoords = centerOfPolygon(element);
        // console.log(cntCoords)
        const centerMarker = new google.maps.Marker({
          cntCoords,
          // icon: pinGlyph,
          map,

        });

        new MarkerClusterer({ 
          markers, map,
          render: (markers) => {
            const content = `
              <div>
                <img src='path/to/your/image.jpg' width='50' height='50' />  <p>Cluster: ${markers.length} Markers</p>  </div>
            `;
      
            const infoWindow = new google.maps.InfoWindow({
              content: content,
            });
      
            const marker = new google.maps.Marker({
              position: markers.getCenter(),
            });
      
            marker.addListener('mouseover', () => infoWindow.open(map, marker));
            marker.addListener('mouseout', () => infoWindow.close());
      
            return marker;
          },
         });

 
        new google.maps.Polygon({
          paths: element,
          map: map,
          title: "My Location",
          fillColor : "#198700",
          fillOpacity : 0.5, 
          strokeColor : "#22f6",
          clickable : true,
          
        });

      }
       
      });

      
        // ends 
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error);
      });
    };
  
    initMap();
  }, [list]); 

  useEffect(()=>{
    fetchAllPlotsCoords()
  },[])

const centerOfPolygon  = (coords) =>  {
    let latitudeSum = 0;
    let longitudeSum = 0;
  
    coords.forEach((point) => {
      latitudeSum += point.lat;
      longitudeSum += point.lng;
    });

    const center = {
      latitude: latitudeSum / coords.length,
      longitude: longitudeSum / coords.length,
    };
    return center;
  }

  
   //fetch landowers
   const fetchAllPlotsCoords = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      setOwnerId(id);

      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        `http://122.160.30.162/cas/api/admin/landowner/plots/${id}`, 
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );
      const newLocations = response.data.data.map(land => {
        return land.plots.map(plot => {
          return plot.plotCoordinates.map(coordinate => ({
            lat: coordinate.latitude,
            lng: coordinate.longitude
          }));
        });
      });

      setList(newLocations);
      console.log(newLocations) 
      
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message); 
    } 
  };




  //fetch all coords of the farmer
  const fetchCoord = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setOwnerId(id); // Update LandId state using the id from URL params
    try {
      const token = localStorage.getItem('cas');
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        `http://122.160.30.162/cas/api/admin/plots/${id}`,
        {
          headers: {
            'x-authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newCoords = response.data.plot_coordinates.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }));
      setCoords(newCoords);
    } catch (error) {
      // Handle API errors gracefully (e.g., display error message to user)
      console.error('Error fetching plot coordinates:', error);
      setError('Failed to retrieve plot coordinates.'); // User-friendly message
    } finally {
      // Optionally, you could perform actions like pausing a loader here
    }
  };


  return (
    <div>
      <Navigation />
      <Sidebar />
      
      <main id="main" className="main">

      <div className="pagetitle">
        
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

          <li className="breadcrumb-item active">
            <Link to={'/land-details' }>
            Land owners
            </Link>  
          </li>
          <li className="breadcrumb-item active">
            Plots
          </li>
          
          <li className="breadcrumb-item active">
              Latitude - Longitude
          </li>
          </ol>
        </nav>
      </div>
        <div className="row  d-flex justify-content-center">
          <div className="col-12 col-lg-12 ">
            <div className="card">
              <div id='map' style={{ height: "80vh" }} className='bg-success' >
              </div>
            </div>
          </div>
        </div>
    </main>
    </div>
  )
    
};

export default Getlocations;
