import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import Sidebar from '../common/Sidebar';
import Navigation from '../common/Navigation';
import { Link } from 'react-router-dom';
import axios from 'axios';

const GetLocation = () => {
  const [LandId, setLandId] = useState(null);
  const [error, setError] = useState(null);
  const [map, setMap] = useState(null); // Store the map instance
  const [coords, setCoords] = useState([]); // State to hold plot coordinates
  const [khasra, setKhasra] = useState([]); // State to hold plot coordinates
  
  
  
  // Function to fetch plot coordinates from backend API
  const fetchCoord = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setLandId(id); // Update LandId state using the id from URL params
    try {
      const token = localStorage.getItem('cas');
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        `http://122.160.30.162/cas/api/admin/plot/${id}`,
        {
          headers: {
            'x-authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const newCoords = response.data.plot_coordinates.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }));
      setCoords(newCoords);
      setKhasra(response.data.slug)
    } catch (error) {
      // Handle API errors gracefully (e.g., display error message to user)
      console.error('Error fetching plot coordinates:', error);
      setError('Failed to retrieve plot coordinates.'); // User-friendly message
    } finally {
      // Optionally, you could perform actions like pausing a loader here
    }
  };

  const loadMap = async () => {
    try {
     
      const loader = new Loader({
        apiKey: "AIzaSyBdPwDzZPe6ISS8cuIIyxe9EcV9bq89xpU", 
        version: "weekly",
        libraries: ["maps", "places"],
      });
      let latLngCenter = centerOfPolygon(coords);
      loader.load().then((google) => {
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 18,
          center: { lat : latLngCenter.latitude, lng : latLngCenter.longitude},
          mapId: "1",
          
        });

        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });

        // start
        // console.log(element)
        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

         const markers = coords.map((position, i) => {
          const label = labels[i % labels.length];
          const marker = new google.maps.Marker({
            position,
            map,
            title: "Show Images",
          });

          
          // marker.addListener("click", () => {
          //   infoWindow.setContent("<img src='https://static.vikaspedia.in/media/images_en/agriculture/best-practices/strip-croptest.jpg' />");
          //   infoWindow.open(map, marker);
          // });
          return marker;
        });


        //for landowner name in the middle of polygon
        
        const landownerName = new google.maps.Marker({
          position: {lat : latLngCenter.latitude, lng : latLngCenter.longitude},
          map: map,
          icon : ' ',
          title: "Khasra No.", // Name displayed on hover
          label: {
            text: khasra + '(owner details here..)', // Name displayed on the marker
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
          },
        });

        map.addListener('zoom_changed', () => {
          const currentZoom = map.getZoom();
          landownerName.setVisible(currentZoom >= 17); // Set marker visibility based on zoom
        });

       

        new google.maps.Polygon({
          paths: coords,
          map: map,
          title: "My Location",
          fillColor : "#198700",
          fillOpacity : 0.7,
          strokeColor : "#22f6",
          clickable : true,
  
        });


      // ends 
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error);
      });

    } catch (error) {
      console.error('Error loading map:', error);
      setError('Failed to load Google Maps.'); // User-friendly message
    }
  };

  // Load map and handle errors
  useEffect(() => {
    fetchCoord();
  }, []); 
  
  useEffect(() => {
    if (coords.length > 0) {
      //when coords updated 
      loadMap(); 
    }
  },[coords])
  
  const centerOfPolygon  = (coords) =>  {
    let latitudeSum = 0;
    let longitudeSum = 0;
  
    coords.forEach((point) => {
      latitudeSum += point.lat;
      longitudeSum += point.lng;
    });

    const center = {
      latitude: latitudeSum / coords.length,
      longitude: longitudeSum / coords.length,
    };
    return center;
  }

  return (
    <div>
      <Navigation />
      <Sidebar />
      {error && <p>Error: {error}</p>}
      <main id="main" className="main">

      <div className="pagetitle">
        
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

          <li className="breadcrumb-item active">
            <Link to={'/land-details' }>
            Land owners
            </Link>  
          </li>
          <li className="breadcrumb-item active">
            Plots
          </li>
          
          <li className="breadcrumb-item active">
              Latitude - Longitude
          </li>
          </ol>
        </nav>
      </div>


        <div className="row  d-flex justify-content-center">
          <div className="col-12 col-lg-12 ">
            <div className="card">
              <div id='map' style={{ height: "80vh" }} className='bg-success' >
              </div>
            </div>
          </div>
          
          
        </div>
    </main>
    </div>
  );
};

export default GetLocation;
