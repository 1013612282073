import React, {useState, useEffect} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import axios from 'axios';
const UserVerify = () => {
  const [users, setUsers] = useState([]); 
  
  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        'http://122.160.30.162/cas/api/admin/users/agent', 
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      setUsers(response.data.users); 
    } catch (error) {
      // setError(error.message);
    } finally {
      //do somthing like pause loader
    }
  };


  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
       <Nav />
        <Sidebar />
       <main id="main" >
        <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">Agent </li>
              </ol>
            </nav>
          </div>

          <section class="section dashboard">
            <div class="row d-flex justify-content-center">
            <div class="col-lg-12">

            <div class="card">
            <div class="card-body">
              <h5 class="card-title">Agent's list</h5>

                {/* filter */}
                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Admins</a></li>
                    <li><a class="dropdown-item" href="#">Agents</a></li>
                    <li><a class="dropdown-item" href="#">All</a></li>
                  </ul>
                </div>

              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.mobile}</td>
                    <td>{user.address.name}</td>
                    <td>
                     {user.created_by.first_name + " " +user.created_by.last_name}
                    </td>
                    <td>
                      {
                        user.verified_at == null ? (
                          <button type="button" class="btn btn-sm btn-success m-1"><i class="bi bi-check-circle"></i></button>

                        ) : null
                      }
                      <button type="button" class="btn btn-sm btn-danger m-1"><i class="bi bi-x-circle"></i></button>
                      </td>
                  </tr>
                ))}
           
                </tbody>
              </table>

                {/* pagination  */}
              {/* <div class="col-lg-12">
                <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                    <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                </ul>
                </nav>
              </div> */}
            
            </div>
          </div>

            </div>
            </div>
          </section>
      </main>
      
    </div>
  );
};

export default UserVerify;