import React, { useEffect } from 'react';
import Nav from '../common/Navigation';
import { useNavigate } from 'react-router-dom';
const Home = () => {
  const navigate = useNavigate();
  //if user already logged in move to dashboard
  const isLoggedIn = localStorage.getItem("cas");  
  
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard')
    }
  },[isLoggedIn, navigate])

  return (
    <div>
      <Nav />
      <main class="mt-5 main p-3">

        <section class="section">
          <div class="row">
            <div class="col-lg-6">

              <div class="card p-5">
                <div class="card-body">
                  <h5 class="card-title">CAS (Crop Anylitics System)</h5>
                  <p>
                  Cultivating Insights: A Glimpse into Crop Analytics Software
The agricultural landscape is undergoing a significant transformation. Traditional farming practices are giving way to data-driven approaches that prioritize efficiency, sustainability, and profitability. At the forefront of this revolution lies Crop Analytics Software.
                  </p>
                  <p>
                  This innovative software suite empowers farmers, agricultural professionals, and stakeholders with a comprehensive set of tools to:
Gain real-time insights: Monitor crop health, soil conditions, and weather patterns in real-time, enabling informed decision-making.
Optimize resource allocation: Analyze data to identify areas requiring specific attention, leading to efficient water usage, fertilizer application, and resource management.
Predict potential issues: Utilize advanced analytics and historical data to anticipate potential threats like pests, diseases, or adverse weather conditions, allowing for proactive measures.
Maximize yields: By understanding the factors influencing crop growth, farmers can adjust their strategies to optimize yields and ensure a bountiful harvest.
                  </p>
                </div>
              </div>

            </div>

            <div class="col-lg-6">

              <div class="card p-5">
                <div class="card-body">
                  <h5 class="card-title">Beyond the field, Crop Analytics Software also offers:</h5>
                  <p>
                  Market analysis: Gain insights into market trends, commodity prices, and demand fluctuations, enabling informed decisions regarding planting, harvesting, and sales.
Traceability and transparency: Track the journey of crops from farm to table, fostering trust and transparency within the agricultural supply chain.
Data-driven decision support: Leverage historical data and predictive models to make informed choices about future agricultural practices, promoting long-term sustainability.
In essence, Crop Analytics Software acts as a powerful digital assistant for the modern farmer.  By harnessing the power of data and advanced analytics, it empowers stakeholders to make informed decisions, optimize resource utilization, and ultimately cultivate a more productive and sustainable agricultural future.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>

        </main>
        
    </div>
  );
};

export default Home;