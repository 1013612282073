import React, {useState, useEffect} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const InspectionDetails = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null); 
  const [list, setList] = useState([]); 
  
  
  //fetch landowers
  const fetchInspections = async () => {
    try {
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        'http://122.160.30.162/cas/api/admin/inspection-list', 
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      setList(response.data.data); 
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message); 
    } 
  };


  useEffect(() => {
    fetchInspections();
  }, []);

 

  return (
    <div>
    <Nav />
     <Sidebar />
    <main id="main" >
     <div className="pagetitle">
         <nav>
           <ol className="breadcrumb">
             <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
             <li className="breadcrumb-item active">Crop Inspection</li>
           </ol>
         </nav>
       </div>

       <section className="section dashboard">
         <div className="row d-flex justify-content-center">
         <div className="col-lg-12">

         <div className="card">
         <div className="card-body">
           <h5 className="card-title">Crop Inspection List 
              <span className='m-4'>:</span>
              <Link className="badge bg-primary text-light" to={'/inspection-map-all'}>
                <i className="bi bi-toggle-on me-1"></i>Map View
              </Link>
            </h5>

             {/* filter */}
             <div className="filter">
               <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
               <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                 <li className="dropdown-header text-start">
                   <h6>Filter</h6>
                 </li>

                 <li><a className="dropdown-item" href="#">Active</a></li>
                 <li><a className="dropdown-item" href="#">Inactive</a></li>
                 <li><a className="dropdown-item" href="#">Closed</a></li>
               </ul>
             </div>

           <table className="table table-hover">
             <thead>
               <tr>
                 <th scope="col">#</th>
                <th scope="col">Plot id</th>
                 <th scope="col">Crop type</th>
                 <th scope="col">Crop</th>
                 <th scope="col">Status</th>
                 <th scope="col">Created at</th>
                 <th scope="col">Action</th>
                </tr>
             </thead>
             <tbody>
            
               {list.map((inspection, index) => (
                  <tr key={inspection.id}>
                    <th scope="row">{index + 1}</th>
                   
                    <td>{inspection.plot_id}</td>
                    <td>{inspection.crop_type}</td>
                    <td>{inspection.crop}</td>
                    <td>
                    {inspection.status == "InActive" && (
                      <span className="font-weight-bold text-secondary">Inactive</span>
                    )}
                    {inspection.status == "Active" && (
                      <span className="font-weight-bold text-success">Active</span>
                    )}
                    {inspection.status == "Viewed/Approved" && (
                      <span className="font-weight-bold text-info">Seen</span>
                    )}
                    {inspection.status == "Closed" && (
                      <span className="font-weight-bold text-warning">Closed</span>
                    )}
                    </td>
                    <td>{inspection.created_at}</td>
                    <td>
                      {/* <button type="button" className="btn btn-info text-light"  data-bs-placement="left" data-bs-toggle="modal" data-bs-target="#basicModal" data-toggle="tooltip" data-placement="top" title="Add new Khasra / Plot" onClick={() => handleModalOpen(inspection.id)}>
                          <i className="bi bi-plus-lg"></i>
                      </button> */}

                      <Link to={'/inspection-info?id=' + inspection.id } className="btn btn-info text-light m-1" data-bs-toggle="tooltip" data-bs-placement="left" title="View Plots" >
                          <i className="bi bi-info-circle"></i>
                      </Link>

                      </td>

                  </tr>
                ))}
               
             </tbody>
           </table>

             {/* pagination  */}
           {/* <div className="col-lg-12">
             <nav aria-label="Page navigation example">
             <ul className="pagination justify-content-end">
                 <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                 <li className="page-item"><a className="page-link" href="#">1</a></li>
                 <li className="page-item"><a className="page-link" href="#">2</a></li>
                 <li className="page-item"><a className="page-link" href="#">3</a></li>
                 <li className="page-item"><a className="page-link" href="#">Next</a></li>
             </ul>
             </nav>
            </div> */}
         

           
        
         </div>
       </div>

         </div>
         </div>
       </section>
   </main>
   
 </div>
  );
};

export default InspectionDetails;