import React, {useState, useEffect} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import datetime from 'moment';

const Plot = () => {
  const [ownerID, setPlotId] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading indicator
  const [error, setError] = useState(null);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setPlotId(id);

    const fetchUsers = async () => {
      try {
        // for error and loaders 
        setIsLoading(true);
        setError(null); 

        const token = localStorage.getItem("cas");
        if (!token) {
          throw new Error('Missing authentication token');
        }

        const response = await axios.get(
          'http://122.160.30.162/cas/api/admin/land?id=' + ownerID, 
          {
            headers: {
              'x-authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        );

        // console.log(response.data);
        setUsers(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false); 
      }
    };

    if (ownerID) { // Fetch users only if ownerID is available
      fetchUsers();
    }
  }, [ownerID]); // Run useEffect only when ownerID changes

  // Render component based on loading and error states


  return (
    <div>
    <Nav />
     <Sidebar />
     <main id="main" className="main">

        <div className="pagetitle">
        
          <nav>
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

            <li className="breadcrumb-item active">
            <Link to={'/land-details' }>
            Land owners
            </Link>
              
              </li>
            <li className="breadcrumb-item active">Plots</li>
            </ol>
          </nav>
        </div>



      <section className="section profile">
        <div className="row">
          <div className="col-xl-4">

            <div className="card">
              <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
                <h2>
                  <span>{users?.first_name  } {users?.last_name  }</span> 
                </h2>
                <h3>7878798345</h3>
              </div>
            </div>

          </div>

          <div className="col-xl-8">

            <div className="card">
              <div className="card-body pt-3">

                <ul className="nav nav-tabs nav-tabs-bordered">

                  <li className="nav-item">
                    <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Lands</button>
                  </li>

                  <li className="nav-item">
                    <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">More info..</button>
                  </li>

                  

                </ul>
                <div className="tab-content pt-2">

                  <div className="tab-pane fade show active profile-overview" id="profile-overview">
                    

                    <h5 className="card-title">
                    <i className="bi bi-info-circle me-1"></i>
                      Land  Details
                    <Link className="badge bg-info text-light m-3" to={'/getLocations?id='+ ownerID}>
                    <i className="bi bi-info-circle"></i> Lat Long 
                    </Link>
                    </h5>
                    


                    {users.lands && users.lands.length > 0 ? users.lands.map((land) => (
                    
                    <div className="row border m-2">
                        {/* <span>{land.user_id}</span>  */}
                        <div className="col-lg-4 col-md-4 label mt-2 ">Khasra No : {land.khasra_no}</div>
                        <div className="col-lg-4 col-md-4 label mt-2 ">Khatauni Number : {"--"}</div>
                        <div className="col-lg-4 col-md-4 label mt-2 ">Revenue Village Code : {"--"}</div>
                        <div className="col-lg-4 col-md-4 label mt-2 mb-4">Total Plots : {land.total_plots}</div>
                        <p className='text-danger'>Plots details :</p>

                        {land.plots && land.plots.length > 0 ? land.plots.map((plot) => (
                          <div className='row'>
                            <div className="col-lg-4 col-md-4 label ">Sub khasra : {plot.slug} </div>
                            <div className="col-lg-4 col-md-4">
                            <span className='text-secondary'> Ownership: </span> 
                            {plot.own_type}
                            </div>
                            <div className="col-lg-4 col-md-4">
                            <span className='text-secondary'> Total Area: </span> 
                            
                            {plot.total_area ? plot.total_area : '00.00acr'}</div>
                            <div className="col-lg-4 col-md-4"> 
                            <span className='text-secondary'> Created at: </span> 
                            
                            {/* {plot.created_at ? plot.created_at : '--'} */}
                            { datetime(plot.created_at).format("D-MM-yy, HH:mm:s")}
                            
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <Link className="badge bg-info text-light" to={'/getLocation?id='+ plot.id}>
                                <i className="bi bi-info-circle me-1"></i> Lat Long 
                              </Link>&nbsp;
                          
                              {/* <Link className="badge bg-primary text-light" to={'/inspection?id='+ plot.id}>
                                <i className="bi bi-toggle-on me-1"></i>Inspection Info
                              </Link> */}

                            </div>
                          </div>

                      ))
                      : (
                        <p>No data found !</p>
                      )
                      }
                        
                      </div>


                    


                    ))
                      : (
                        <p>No data found !</p>
                      )
                  }


                    {/* <div className="row">
                      <div className="col-lg-3 col-md-4 label ">123/2A</div>
                      <div className="col-lg-3 col-md-4">Self Owned</div>
                      <div className="col-lg-6 col-md-6">
                        <Link className="badge bg-success text-light" to={'/getLocation'}>
                          <i className="bi bi-plus-lg me-1"></i> Lat Long 
                        </Link>&nbsp;
                    
                        <span className="badge bg-primary text-light"><i className="bi bi-toggle-on me-1"></i> Start Inspection </span>
                      </div>
                      
                    </div>

                    <div className="row">
                    <div className="col-lg-3 col-md-4 label ">123/2A</div>
                      <div className="col-lg-3 col-md-4">Rented</div>
                      <div className="col-lg-6 col-md-6">
                        <span className="badge bg-success text-light"><i className="bi bi-plus-lg me-1"></i> Lat Long</span> &nbsp;
                        <span className="badge bg-primary text-light"><i className="bi bi-toggle-on me-1"></i> Start Inspection </span>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-3 col-md-4 label ">123/2A</div>
                      <div className="col-lg-3 col-md-4">Self Owned</div>
                      <div className="col-lg-6 col-md-6">
                        <span className="badge bg-success text-light"><i className="bi bi-plus-lg me-1"></i> Lat Long</span> &nbsp;
                        <span className="badge bg-primary text-light"><i className="bi bi-toggle-on me-1"></i> Start Inspection </span>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-3 col-md-4 label ">123/2A</div>
                      <div className="col-lg-3 col-md-4">Rented</div>
                      <div className="col-lg-6 col-md-6">
                        <span className="badge bg-success text-light"><i className="bi bi-plus-lg me-1"></i> Lat Long</span> &nbsp;
                        <span className="badge bg-primary text-light"><i className="bi bi-toggle-on me-1"></i> Start Inspection </span>
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-3 col-md-4 label ">123/2A</div>
                      <div className="col-lg-3 col-md-4">Self Owned</div>
                      <div className="col-lg-6 col-md-6">
                        <span className="badge bg-success text-light"><i className="bi bi-plus-lg me-1"></i> Lat Long</span> &nbsp;
                        <span className="badge bg-primary text-light"><i className="bi bi-toggle-on me-1"></i> Start Inspection </span>
                      </div>
                    </div> */}

                  </div>

                  <div className="tab-pane fade profile-edit pt-3" id="profile-edit">

                  
                    <form>
                    

                      <div className="row mb-3">
                        <label  className="col-md-4 col-lg-3 col-form-label">About</label>
                        <div className="col-md-8 col-lg-9">
                          {/* <textarea name="about" className="form-control" id="about" style={{height: "100px"}}>Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</textarea> */}
                        </div>
                      </div>


                    </form>
                    
                  </div>

                


                </div>
                
              </div>
            </div>

          </div>
        </div>
      </section>

</main>
   
 </div>
  );
};

export default Plot;