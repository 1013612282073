import MyRoutes from './routing/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
function App() {
  return (
    <div>
      <MyRoutes />
    </div>
  );
}
export default App;
