import axios from 'axios';

const token = localStorage.getItem("cas");
if (!token) {
  // throw new Error('Missing authentication token in local storage.');
}
const api = axios.create({
  baseURL: 'http://122.160.30.162/cas/api/', 
  headers: {
    'Content-Type': 'application/json', 
  },
});

const ApiService = {
  async request(endpoint, method, headers = {}, body = {}) {
    try {
      const response = await axios({
        method,
        url: endpoint,
        headers,
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors appropriately, e.g., throw an error or return a default value
      throw error; // Replace with your desired error handling logic
    }
  },
};

export default ApiService;
