import React, {useState, useEffect} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const Land = () => {
  const navigate = useNavigate();
    const [properties, setProperties] = useState([]); 
    const [slug, setNewKhasraNumber] = useState(''); 
    const [own_type, setNewType] = useState(''); 
    const [ownerID, setownerID] = useState();

    const handleAddProperty = (event) => {
      event.preventDefault(); 
  
      if (!slug.trim() || !own_type.trim()) {
        return; 
      }
  
      const newProperties = [...properties]; 
      newProperties.push({
        id: Date.now(), 
        slug: slug,
        own_type: own_type,
      });
      
     
      setProperties(newProperties); 
      setFormData({ ...formData,"plot_details": newProperties, "total_plots" : newProperties.length });

      setNewKhasraNumber(''); 
      setNewType(''); 
    };
  
    const handleRemoveProperty = (id) => {
      const filteredProperties = properties.filter((property) => property.id !== id); // Filter out the property to be removed

      setProperties(filteredProperties); 
      setFormData({ ...formData,"plot_details": filteredProperties, "total_plots" : filteredProperties.length });
    
    };
  
    const handleKhasraNumberChange = (event) => {
      setNewKhasraNumber(event.target.value);
    };
    const handleTypeChange = (event) => {
      setNewType(event.target.value);
    };

  const [users, setUsers] = useState([]); 
  const [error, setError] = useState(null); 
  const [popup, setPopUp] = useState([]); 
  
  
  
  //fetch landowers
  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        'http://122.160.30.162/cas/api/admin/users/landowner', 
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      setUsers(response.data.users); 
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message); 
    } 
  };


  useEffect(() => {
    fetchUsers();
  }, []);

  //set owner ID in modal form
  const handleModalOpen =(ownerID) => {
    setownerID(ownerID)
    setFormData({ ...formData,"owner_id": ownerID });

    //fetch owner land/plot details
    const fetchLandDetails = async () => {
      try {
        const token = localStorage.getItem("cas");
        if (!token) {
          throw new Error('Missing authentication token in local storage.');
        }
  
        const response = await axios.get(
          'http://122.160.30.162/cas/api/admin/users/landowner', 
          {
            headers: {
              'x-authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        );
  
        setPopUp(response.data.users); 
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(error.message); 
      } 
    };

  };

  //form data 
  const [formData, setFormData] = useState({
    owner_id : '', 
    khatauniNum : '', 
    revenueCode : '', 
    khasra_no : '', 
    total_plots : 0,
    plot_details : []
  });
  const handleChange = (event) => {    
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  

  const updatePlotDetails =  async (e) => {
    e.preventDefault()
    console.log(formData)
    
    try {
      const token = localStorage.getItem("cas");
      const response = await fetch('http://122.160.30.162/cas/api/admin/land', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'x-authorization': 'Bearer ' + token,
        },
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        alert("success")
        navigate('/plot-details?id=' + ownerID);
        
      } else {
        const errorData = await response.json();
        console.log(errorData.message);
        setError(errorData.message || 'Error');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred. Please try again later.');
    }
}

  return (
    <div>
    <Nav />
     <Sidebar />
    <main id="main" >
     <div className="pagetitle">
         <nav>
           <ol className="breadcrumb">
             <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
             <li className="breadcrumb-item active">Land owners</li>
           </ol>
         </nav>
       </div>

       <section className="section dashboard">
         <div className="row d-flex justify-content-center">
         <div className="col-lg-12">

         <div className="card">
         <div className="card-body">
           <h5 className="card-title">Land ownwer's list</h5>

             {/* filter */}
             <div className="filter">
               <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
               <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                 <li className="dropdown-header text-start">
                   <h6>Filter</h6>
                 </li>

                 <li><a className="dropdown-item" href="#">Admins</a></li>
                 <li><a className="dropdown-item" href="#">Agents</a></li>
                 <li><a className="dropdown-item" href="#">All</a></li>
               </ul>
             </div>

           <table className="table table-hover">
             <thead>
               <tr>
                 <th scope="col">#</th>
                 <th scope="col">Name</th>
                 <th scope="col">Email</th>
                 <th scope="col">Phone</th>
                 <th scope="col">Address</th>
                 <th scope="col">Total Area (In Acers)</th>
                 <th scope="col">Action</th>
               </tr>
             </thead>
             <tbody>
            
               {users.map((user, index) => (
                  <tr key={user.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.mobile}</td>
                    <td>{user.address.name}</td>
                    <td> 00.00 </td>
                    <td>
                      <button type="button" className="btn btn-info text-light"  data-bs-placement="left" data-bs-toggle="modal" data-bs-target="#basicModal" data-toggle="tooltip" data-placement="top" title="Add new Khasra / Plot" onClick={() => handleModalOpen(user.id)}>
                          <i className="bi bi-plus-lg"></i>
                      </button>

                      <Link to={'/plot-details?id=' + user.id } className="btn btn-info text-light m-1" data-bs-toggle="tooltip" data-bs-placement="left" title="View Plots" >
                          <i className="bi bi-info-circle"></i>
                      </Link>

                      </td>

                  </tr>
                ))}
               
             </tbody>
           </table>

             {/* pagination  */}
           {/* <div className="col-lg-12">
             <nav aria-label="Page navigation example">
             <ul className="pagination justify-content-end">
                 <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                 <li className="page-item"><a className="page-link" href="#">1</a></li>
                 <li className="page-item"><a className="page-link" href="#">2</a></li>
                 <li className="page-item"><a className="page-link" href="#">3</a></li>
                 <li className="page-item"><a className="page-link" href="#">Next</a></li>
             </ul>
             </nav>
            </div> */}
         

            {/* open modal incase farmer doesn't have  khasra no.*/}    
            <div className="modal fade" id="basicModal" >
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Add Khasra/Plot details as per govt. records</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <form className="row" onSubmit={updatePlotDetails}>
                  <input type="hidden" name='owner_id' id='owner_id' value={ownerID} onChange={handleChange} />
                    <div className="modal-body">
                      <div className='commond_fields row d-flex justify-content-center '>

                        <div className="col-md-6 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="khatauniNum" name='khatauniNum'
                            placeholder='Enter Khatauni Number..'
                            value={formData.khatauniNum} onChange={handleChange}
                            />
                        </div>
                        <div className="col-md-6 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="revenueCode" name='revenueCode'
                            value={formData.revenueCode} onChange={handleChange}
                            placeholder='Enter Revenue Village Code..'
                            />
                        </div>
                        <div className="col-md-6 mt-2 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="khasra_no" name='khasra_no'
                            value={formData.khasra_no} onChange={handleChange}
                            placeholder='Enter Khasra Number..'
                            />
                        </div>
                      </div>
                    </div>

                    <div className='p-3'>
                    <div className="row d-flex justify-content-center">
                        <div className='notice text-center'>
                        <span className="">Enter Plot/ Plots details within Khasra number. </span>
                        <q>For Single Plot Area Copy above Khasra no.</q>
                        </div>
                        
                        <hr />
                        <div className="col-md-5 text-center">
                            <label htmlFor="inputfName" className="form-label">Enter Khasra Number</label>
                            <input
                            type="text"
                            className="form-control"
                            id="inputfName" placeholder='enter here..'
                            value={slug}
                            onChange={handleKhasraNumberChange}
                            />
                        </div>
                        <div className="col-md-5 text-center">
                            <label htmlFor="inputState" className="form-label">Type</label>
                            <select id="inputState" className="form-select" value={own_type} onChange={handleTypeChange}>
                            <option value="">Choose...</option>
                            <option value="owned">Self Owned</option>
                            <option value="rented">Rented</option>
                            </select>
                        </div>
                        <div className="col-md-2 text-center">
                            <button type="button" onClick={handleAddProperty} className="badge bg-primary rounded-pill mt-4">Add</button>
                        </div>
                        </div>

                

                        <ul className="list-group mt-2">
                            {properties.map((property) => (
                                <li key={property.id} className="list-group-item d-flex justify-content-between align-items-center">
                                <span>
                                {property.slug}
                                </span> 
                                {property.own_type}
                            
                                <span className="badge bg-danger rounded-pill" onClick={() => handleRemoveProperty(property.id)}> Remove</span>
                            </li>
                            ))}
                        </ul>

                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" className="btn btn-primary">Save changes</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        
         </div>
       </div>

         </div>
         </div>
       </section>
   </main>
   
 </div>
  );
};

export default Land;