import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import Sidebar from '../common/Sidebar';
import Navigation from '../common/Navigation';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const Inspection = () => {
  const [inspectionId, setInspection] = useState(null);
  const [error, setError] = useState(null);
  
  const [coords, setCoords] = useState([]); // State to hold plot coordinates
  const [cropCoords, setCropCoords] = useState([]);
  const [cropImages, setCropImages] = useState([]);
  
  
  // Function to fetch plot coordinates from backend API
  // const fetchCoord = async () => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const id = urlParams.get('id');
  //   setInspection(id); // Update Inspection state using the id from URL params
  //   try {
  //     const token = localStorage.getItem('cas');
  //     if (!token) {
  //       throw new Error('Missing authentication token in local storage.');
  //     }

  //     const response = await axios.get(
  //       `http://122.160.30.162/cas/api/admin/plot/${id}`,
  //       {
  //         headers: {
  //           'x-authorization': `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     const plotCoords = response.data.plot_coordinates.map((coord) => ({
  //       lat: coord.latitude,
  //       lng: coord.longitude,
  //     }));
      
  //     setCoords(plotCoords);
      
  //   } catch (error) {
  //     // Handle API errors gracefully (e.g., display error message to user)
  //     console.error('Error fetching plot coordinates:', error);
  //     setError('Failed to retrieve plot coordinates.'); // User-friendly message
  //   } finally {
  //     // Optionally, you could perform actions like pausing a loader here
  //   }
  // };

  const loadMap = async () => {
    try {
      console.log("cropCoords",cropCoords) 
      console.log("Coords", coords) 
      
      const loader = new Loader({
        apiKey: "AIzaSyBdPwDzZPe6ISS8cuIIyxe9EcV9bq89xpU", 
        version: "weekly",
        libraries: ["maps", "places"],
      });
      let latLngCenter = centerOfPolygon(coords);
      loader.load().then((google) => {
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 19,
          center: { lat : latLngCenter.latitude, lng : latLngCenter.longitude},
          mapId: "1",
          
        });

        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });

        // start
        // console.log(element)
        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

         const markers = coords.map((position, i) => {
          const label = labels[i % labels.length];
          const marker = new google.maps.Marker({
            position,
            map,
            icon : '  '
          });

          
          // marker.addListener("click", () => {
          //   infoWindow.setContent("<img src='http://localhost:8000/storage/6618e05d72e1e.jpg' />");
          //   infoWindow.open(map, marker);
          // });
          return marker;
        });


        //for landowner name in the middle of polygon
        
        if (cropImages.length > 0) {
          console.log(cropImages)
          cropImages.map((image) => {
            const customMarkers = new google.maps.Marker({
              position:  {
                "lat": image.lat,
                "lng": image.lng
              },
              map: map,
              icon : "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
              
            });

            customMarkers.addListener("mouseover", () => {
              infoWindow.setContent(`<img  style='width:300px' src='${image.img}' />`);
              infoWindow.open(map, customMarkers);
            });
            
            customMarkers.addListener("mouseout", () => {
              infoWindow.close();
            });
          })
        }

       

        // map.addListener('zoom_changed', () => {
        //   const currentZoom = map.getZoom();
        //   customMarkers.setVisible(currentZoom >= 17); // Set marker visibility based on zoom
        // });

       

        new google.maps.Polygon({
          paths: coords,
          map: map,
          title: "My Location",
          fillColor : "#198700",
          fillOpacity : 0.3,
          strokeColor : "#22f6",
          clickable : true,
  
        });
        // console.log(cropCoords)
        new google.maps.Polygon({
          paths: cropCoords,
          map: map,
          title: "Location",
          fillColor : "#ffffff",
          fillOpacity : 0.6,
          strokeColor : "#00f",
          clickable : true,
  
        }); 
      // ends 
      })
      .catch(error => {
        console.error('Error loading Google Maps API:', error);
      });

    } catch (error) {
      console.error('Error loading map:', error);
      setError('Failed to load Google Maps.'); // User-friendly message
    }
  };

  // Load map and handle errors
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setInspection(id);
  }, []); 
  
  useEffect(() => {
    if (coords.length > 0) {
      //when coords updated 
      loadMap(); 
    }
  },[coords,cropCoords,cropImages])
   
  const centerOfPolygon  = (coords) =>  {
    let latitudeSum = 0;
    let longitudeSum = 0;
  
    coords.forEach((point) => {
      latitudeSum += point.lat;
      longitudeSum += point.lng;
    });

    const center = {
      latitude: latitudeSum / coords.length,
      longitude: longitudeSum / coords.length,
    };
    return center;
  }

  //fetch inspection details
  const fetchInspection= async () => {
     // Update LandId state using the id from URL params
    try {
      const token = localStorage.getItem('cas');
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      } 

      const response = await axios.get(
        `http://122.160.30.162/cas/api/admin/inspection?id=${inspectionId}`,
        {
          headers: {
            'x-authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setCropImages(response.data.data[0].images)

      setCropCoords(response.data.data[0].coords);

      const coords = response.data.data[0].plot.plot_coordinates.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }));
      setCoords(coords)

    } catch (error) {
      console.error('Error fetching plot coordinates:', error);
      setError('Failed to retrieve plot coordinates.'); // User-friendly message
    } finally {
      
    }
  };

  useEffect(() => {
      fetchInspection(); 
  },[inspectionId])


  return (
    <div>
      <Navigation />
      <Sidebar />
      {error && <p>Error: {error}</p>}
      <main id="main" className="main">

      <div className="pagetitle">
        
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

          <li className="breadcrumb-item active">
            <Link to={'/inspection-details' }>
            Inspections
            </Link>  
          </li>
          <li className="breadcrumb-item active">
            Details
          </li>
          
          <li className="breadcrumb-item active">
              Map view
          </li>
          </ol>
        </nav>
      </div>


        <div className="row  d-flex justify-content-center">
          <div className="col-12 col-lg-12 ">
            <div className="card">
              <div id='map' style={{ height: "80vh" }} className='bg-success' >
              </div>
            </div>
          </div>
          
         
        </div>
    </main>
    </div>
  );
};

export default Inspection;
