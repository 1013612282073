import React, {useEffect, useState} from 'react';
import Nav from '../../common/Navigation';
import Sidebar from '../../common/Sidebar';
import ApiService from '../../../services/apiService';
const Roles = () => {
  const [roles, setRoles] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }
      const endpoint = 'http://122.160.30.162/cas/api/admin/roles';
      const method = 'GET';
      const headers = { 
        'x-authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }; 
      
      const response = await ApiService.request(endpoint, method, headers);
      setRoles(response);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <div>
       <Nav />
        <Sidebar />
       <main id="main" >
        <div className="pagetitle">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li className="breadcrumb-item active">Roles</li>
              </ol>
            </nav>
          </div>

          <section className="section dashboard">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-12">

            {/* <div className="card">
              <div className="card-body">
                <h5 className="card-title">Add new role</h5>

                  
                  {error && <div className="text-danger">{error}</div>}

              </div>
            </div> */}


            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Roles's list</h5>

                  {/* filter */}
                  <div className="filter">
                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                      <li className="dropdown-header text-start">
                        <h6>Filter</h6>
                      </li>

                      <li><a className="dropdown-item" href="#">Date</a></li>
                      <li><a className="dropdown-item" href="#">All</a></li>
                    </ul>
                  </div>

                  {error && <div className="text-danger">{error}</div>}

                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Slug</th>
                      <th scope="col">Action</th>
                      </tr>
                  </thead>
                  <tbody>
                    
                  {roles?.roles.map((role, index) => (
                  <tr key={role.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{role.name}</td>
                      <td>{role.slug}</td>
                      <td>
                        <button type="button" class="btn btn-sm btn-danger m-1"><i class="bi bi-x-circle"></i></button>
                        </td>
                    </tr>
                  ))}

                  </tbody>
                </table>

              
              
              </div>
            </div>

            </div>
            </div>
          </section>
      </main>
      
    </div>
  );
};

export default Roles;