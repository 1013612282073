import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [userRole, setUserRole] = useState([]); 
  const User =  JSON.parse(localStorage.getItem("casUser"));
  
  useEffect(() => {
    
    User.roles.map((role) => {
      setUserRole(role.slug)
    })
    // console.log(userRole)
  },[User])

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">

          <Link className="nav-link" to={'/dashboard'} >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>

        </li>
        
        {userRole.includes("super_admin") ?  

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#role-permission-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-person"></i>
            <span>Role & Permissions</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="role-permission-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>
              <Link to={'/roles'} >
                <i className="bi bi-circle"></i>
                <span>Roles</span>
              </Link>
              <Link to={'/permissions'} >
                <i className="bi bi-circle"></i>
                <span>Permissions</span>

              </Link>
              <Link to={'/role-permissions'} >
                <i className="bi bi-circle"></i>
                <span>Manage Role & Permissions </span>

              </Link>
            </li>
           
          </ul>
        </li>
        : null
        }
        
        {userRole.includes("super_admin") ?
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#location-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-geo-alt"></i>
            <span>Locations</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="location-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>
              <Link to={'/dashboard'} >
                <i className="bi bi-circle"></i>
                <span>Manage States, Cities & Addresses </span>
              </Link>
              <Link to={'/locations'} >
                <i className="bi bi-circle"></i>
                <span>Location Master</span>

              </Link>
            </li>
           
          </ul>
        </li>
        :null
        }


        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-person"></i>
            <span>Users</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="components-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>

              {/* for admin and super admin */}
              {userRole.includes("super_admin") || userRole.includes("admin") ? (
                <Link to={'/user'} >
                  <i className="bi bi-circle"></i>
                  <span>New User</span>
                </Link>
              ) : null}
              
              <Link to={'/user-list'} >
                <i className="bi bi-circle"></i>
                <span>Users Lists</span>

              </Link>

              {userRole.includes("super_admin") ? 
              <Link to={'/user-verify'} >
                <i className="bi bi-circle"></i>
                <span>Verify Agents</span>
              </Link>
              : null
              }
            </li>
           
          </ul>
        </li>
        
      {/* admin and agent  */}
      <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#lands-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-file-earmark-bar-graph"></i>
            <span>Manage (Lands/Plots) </span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="lands-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li>
              <Link to={'/land-details'} >
                <i className="bi bi-circle"></i>
                <span>Add Land Details</span>

              </Link>
            </li>
           
          </ul>
          
        </li>
        {userRole.includes("agent") || userRole.includes("admin") ? (
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            data-bs-target="#inspection-nav"
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-bullseye"></i>
            <span>Manage Inspection </span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id="inspection-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
           <li>
              <Link to={'/inspection-details'} >
                <i className="bi bi-circle"></i>
                <span>Manage Inspection </span>

              </Link>
            </li>
           
          </ul>
          
        </li>
        ) : null 
        }
      </ul>
    </aside>
  );
};

export default Sidebar;
