import React, { useState } from 'react';
import Nav from './common/Navigation'
import { useNavigate } from 'react-router-dom';
import { useUser } from '../providers/UserProvider';

function LoginForm() {
  const { isLoggedIn } = useUser();
  const [email, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const changeHandler = (e) => {
    if (e.target.name === 'email') {
      setUserEmail(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an object with only username and password
    const loginData = {
      email,
      password,
    };

    try {
      
      const response = await fetch('http://122.160.30.162/cas/api/auth', {
        // const response = await fetch('http://122.160.30.162/cas/api/auth', {
        method: 'POST',
        body: JSON.stringify(loginData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const res = await response.json();
        
        localStorage.setItem("cas", res.access_token)
        localStorage.setItem("casUser", JSON.stringify(res.user))
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Login failed');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('An error occurred. Please try again later.');
    }
  };

  
  return (
    
    <div>
        <Nav />
        
      <section className="section register min-vh-60   d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                  <span className="d-none d-lg-block">Admin</span>
              </div>

              <div className="card mb-2">
                <div className="card-body">

                {error && (
                    <div className='mt-1 p-2 text-center bg-danger text-light'>
                        {error ?? ''}
                    </div>
                )}
                  <div className="pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p className="text-center small">Enter your email & password to login</p>
                  </div>

                  <form className="row g-3 needs-validation" onSubmit={handleSubmit}>

                    <div className="col-12">
                      <label for="yourUseremail" className="form-label">Email</label>
                      <div className="input-group has-validation">
                        <input type="email" name="email" value={email} onChange={changeHandler} className="form-control" id="yourUseremail" required  />
                        <div className="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label for="yourPassword" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="yourPassword" required value={password} onChange={changeHandler} />
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                        <label className="form-check-label" for="rememberMe">Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100" name='submit' type="submit">Login</button>
                    </div>
                  
                  </form>

                </div>
              </div>


            </div>
          </div>
        </div>

      </section>

    </div>
  );
}

export default LoginForm;