import React, {useEffect, useState} from 'react';
import Nav from '../../common/Navigation';
import Sidebar from '../../common/Sidebar';
import ApiService from '../../../services/apiService';
const Locations = () => {
 
 

  return (
    <div>
       <Nav />
        <Sidebar />
       <main id="main" >
        <div className="pagetitle">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li className="breadcrumb-item active">Location Master</li>
              </ol>
            </nav>
          </div>

          <section className="section dashboard">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-12">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Manage Location/ Address</h5>
 
                  <form className="row" >
                    <div className="modal-body">
                      <div className='commond_fields row '>

                        <div className="col-md-3 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="khasraNum"
                            placeholder='Search State..'
                            />
                        </div>

                        <div className="col-md-3 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="khasraNum"
                            placeholder='Search City..'
                            />
                        </div>
                        <div className="col-md-6 text-center">
                            <input
                            type="text"
                            className="form-control"
                            id="revenueCode"
                            placeholder='Search Address from Master..'
                            />
                        </div>
                        <div className="col-md-4  mt-4">
                          <button type="submit" className="btn btn-primary">Assign Address to State and City</button>
                        </div>
                      </div>
                    </div>

                    
                </form>

              </div>
            </div>


            <div className="card">
            <div className="card-body">
              <h5 className="card-title">Addresses list</h5>

              {/* search */}
                
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Address</th>
                    <th scope="col">City</th>
                    <th scope="col">State</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                  
                

                </tbody>
              </table>

             
            
            </div>
          </div>

            </div>
            </div>
          </section>
      </main>
      
    </div>
  );
};

export default Locations;