import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("cas");  

  useEffect(() => {
    if (isLoggedIn) { 
      localStorage.removeItem("cas");
      localStorage.removeItem("casUser");
      navigate('/login'); 
    }
  }, [isLoggedIn, navigate]); 

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login'); 
    }
  }, [isLoggedIn, navigate]); 

  return null;
}

export default Logout;
