import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../components/Login';
import Logout from '../components/Logout' 
import Home from '../components/web/Home';
import Dashboard from '../components/common/Dashboard'; 
import User from '../components/web/User';
import UserList from '../components/superAdmin/User-list';
import UserVerify from '../components/superAdmin/User-verify';
import Land from '../components/web/Land';
import ProtectedRoute from '../routing/ProtectedRoutes';
import Plot from '../components/web/Plot';
import Roles from '../components/superAdmin/Role_Permissions/Roles';
import Permissions from '../components/superAdmin/Role_Permissions/Permissions';
import RolesPermissions from '../components/superAdmin/Role_Permissions/RolesPermissions';
import Locations from '../components/superAdmin/locations/Locations';
import Profile from '../components/web/Profile';
// import GetLocation from '../components/web/Location_old';
import GetLocation from '../components/web/LocationWorking';
import Getlocations from '../components/web/Getlocations';
import Inspection from '../components/web/Inspection';
import InspectionData from '../components/web/Inspection-data';
import InspectionDetails from '../components/web/InspectionDetails';
import InspectionMapView from '../components/web/Inspection-mapView';
const MyRoutes = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/" element={<Home />} />
      <Route path="user" element={<ProtectedRoute><User /></ProtectedRoute>} />
      <Route path="/user-list" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
      <Route path="/user-verify" element={<ProtectedRoute><UserVerify /></ProtectedRoute>} />
      <Route path="/land-details" element={<ProtectedRoute><Land /></ProtectedRoute>} />
      <Route path="/plot-details" element={<ProtectedRoute><Plot /></ProtectedRoute>} />
      <Route path="/roles" element={<ProtectedRoute><Roles /></ProtectedRoute>} />
      <Route path="/permissions" element={<ProtectedRoute><Permissions /></ProtectedRoute>} />
      <Route path="/role-permissions" element={<ProtectedRoute><RolesPermissions /></ProtectedRoute>} />
      <Route path="/locations" element={<ProtectedRoute><Locations /></ProtectedRoute>} />
      <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/getLocation" element={<ProtectedRoute><GetLocation /></ProtectedRoute>} />
      <Route path="/getLocations" element={<ProtectedRoute><Getlocations /></ProtectedRoute>} />
      <Route path="/inspection" element={<ProtectedRoute><Inspection /></ProtectedRoute>} />
      <Route path="/inspection-data" element={<ProtectedRoute><InspectionData /></ProtectedRoute>} />
      <Route path="/inspection-details" element={<ProtectedRoute><InspectionDetails /></ProtectedRoute>} />
      <Route path="/inspection-info" element={<ProtectedRoute><InspectionData /></ProtectedRoute>} />
      <Route path="/inspection-map-all" element={<ProtectedRoute><InspectionMapView /></ProtectedRoute>} />
      
    </Routes>
  </BrowserRouter>
  );
};

export default MyRoutes;
