import React, {useEffect, useState} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import axios  from 'axios';
const UserList = () => {

  const [users, setUsers] = useState([]); // State to store fetched users
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  // Function to fetch users
  const fetchUsers = async () => {
    try {
      setIsLoading(true); // Set loading state to true

      // Retrieve token from local storage, handle missing token gracefully
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        'http://122.160.30.162/cas/api/admin/user', // Replace with your endpoint
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(response.data.users)
      setUsers(response.data.users); // Update users state
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(error.message); // Set error message
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  // Fetch users on component mount (using useEffect hook)
  useEffect(() => {
    fetchUsers();
  }, []);


  return (
    <div>
       <Nav />
        <Sidebar />
       <main id="main" >
        <div className="pagetitle">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li className="breadcrumb-item active">User</li>
              </ol>
            </nav>
          </div>

          <section className="section dashboard">
            <div className="row d-flex justify-content-center">
            <div className="col-lg-12">

            <div className="card">
            <div className="card-body">
              <h5 className="card-title">User's list</h5>

                {/* filter */}
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a className="dropdown-item" href="#">Admins</a></li>
                    <li><a className="dropdown-item" href="#">Agents</a></li>
                    <li><a className="dropdown-item" href="#">Landowners</a></li>
                    <li><a className="dropdown-item" href="#">All</a></li>
                  </ul>
                </div>

                {isLoading && <div>Loading users...</div>}
                {error && <div className="text-danger">{error}</div>}

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Address</th>
                    <th scope="col">Role</th>
                    <th scope='col'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{user.first_name} {user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.mobile}</td>
                    <td>{user.address.name}</td>
                    <td>
                      {user.roles.map((role, index) => (
                      <span key={role.id}>{role.name}{index !== user.roles.length - 1 ? ', ' : ''}</span>
                    ))}  
                    </td>
                    <td>
                      <button type="button" class="btn btn-sm btn-danger m-1"><i class="bi bi-x-circle"></i></button>
                      </td>
                  </tr>
                ))}
                </tbody>
              </table>

                {/* pagination  */}
              {/* <div className="col-lg-12">
                <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>
                </nav>
              </div> */}
            
            </div>
          </div>

            </div>
            </div>
          </section>
      </main>
      
    </div>
  );
};

export default UserList;