import React, {useState, useEffect} from 'react';
import Nav from '../common/Navigation';
import Sidebar from '../common/Sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService';

const User = () => {

    const [roles, setRoles] = useState(null);
    const [states, setStates] = useState(null);
    const [cities, setCities] = useState(null);
    
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("cas");
        if (!token) {
          throw new Error('Missing authentication token in local storage.');
        }
        const endpoint = 'http://122.160.30.162/cas/api/admin/roles';
        const method = 'GET';
        const headers = { 
          'x-authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }; 
        
        const response = await ApiService.request(endpoint, method, headers);
        setRoles(response);
      } catch (error) {
        setError(error);
      }
    };

    const fetchStates = async () => {
      try {
        const token = localStorage.getItem("cas");
        if (!token) {
          throw new Error('Missing authentication token in local storage.');
        }
        const endpoint = 'http://122.160.30.162/cas/api/states';
        const method = 'GET';
        const headers = { 
          'x-authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }; 
        
        const response = await ApiService.request(endpoint, method, headers);
        setStates(response);
      } catch (error) {
        setError(error);
      }
    };

    const fetchCities = async (stateID) => {
      try {
        const token = localStorage.getItem("cas");
        if (!token) {
          throw new Error('Missing authentication token in local storage.');
        }
        const endpoint = 'http://122.160.30.162/cas/api/cities?state=' + stateID;
        const method = 'GET';
        const headers = { 
          'x-authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        }; 
        
        const response = await ApiService.request(endpoint, method, headers);
        setCities(response);
      } catch (error) {
        setError(error);
      }
    };
    useEffect(() => {
      fetchData();
      fetchStates();
    }, []);


  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fname: '',
    lname : '',
    email: '',
    password : '',
    mobile : '',
    role : 0,
    address : '',
    state : '',
    cities_state_id : 0
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (event) => {
    if (event.target.name == "state") {
      fetchCities(event.target.value)
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      
      const token = localStorage.getItem("cas");
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }
      const response = await axios.post(
        'http://122.160.30.162/cas/api/admin/user', // Replace with your endpoint
        formData,
        {
          headers: {
            'x-authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );
    
      setSuccess(true);
      setError(null);
    
      alert("success")
      navigate('/user-list');
      console.log('Form submitted successfully:', response);
    } catch (error) {
      console.error('Error submitting form:', error);
    
      switch (error.response.status) {

        case 400:
          setError('Invalid request data. Please check your input fields.');
          break;
        case 401:
          setError('Unauthorized access. Please check your credentials.');
          break;
        case 422:
          const res = JSON.stringify(error.response.data.error);
          setError(res);
          break;
        case 500:
          setError('Internal server error. Please try again later.');
          break;
        default:
          setError('An unexpected error occurred. Please try again later.');
      }
      // Extract specific error information (if available) and provide a more informative error message
    
      // setError(errorMessage);
      setSuccess(false); // Clear any previous success messages
    }
    
  };

  return (
    <div>
       <Nav />
        <Sidebar />
       <main id="main" >
        <div class="pagetitle">
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li class="breadcrumb-item active">User</li>
              </ol>
            </nav>
          </div>

          <section class="section dashboard">
            <div class="row d-flex justify-content-center">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                <div className='text-center'>
                  {error && <div className="text-danger">{error}</div>}
                  {success && <div className="text-success"> {success}</div>}
                </div>
                  
                <h5 class="card-title">Add new user</h5>

                <form class="row g-3" onSubmit={handleSubmit}>
                  <div class="col-md-4">
                    <label for="inputfName" class="form-label">First Name</label>
                    <input type="text" id="fname" class="form-control" name="first_name" value={formData.first_name} onChange={handleChange} required />
                  </div>
                  <div class="col-md-4">
                    <label for="inputlName" class="form-label">Last Name</label>
                    <input type="text" class="form-control" id="inputlName"  name="last_name" value={formData.last_name} onChange={handleChange} required  />
                  </div>
                  <div class="col-md-4">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail" name='email' value={formData.email} onChange={handleChange} />
                  </div>
                  <div class="col-md-4">
                    <label for="inputPassword5" class="form-label">Password</label>
                    <input type="password" class="form-control" id="inputPassword5"  name='password' value={formData.password} onChange={handleChange}  />
                  </div>
                  <div class="col-md-4">
                    <label for="inputmobile" class="form-label">Mobile Number</label>
                    <input type="password" class="form-control" id="inputmobile"  name='mobile' value={formData.mobile} onChange={handleChange}  />
                  </div>
                  <div class="col-md-4">
                    <label for="inputrole" class="form-label">Select Role</label>
                    <select id="inputRole" class="form-select"  name='role' value={formData.role} onChange={handleChange}  defaultValue=" ">
                      <option>Choose...</option>
                      
                      {roles?.roles.map((role, index) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>                    
                  </div>
                  <div class="col-12">
                    <label for="inputAddress2" class="form-label">Address</label>
                    <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"  name='address_id' value={formData.address_id} onChange={handleChange}  />
                  </div>
                  <div class="col-md-6">
                    <label for="inputState" class="form-label">State</label>
                    <select id="inputState" class="form-select"  name='state' value={formData.state} onChange={handleChange}  >
                      <option selected>Choose...</option>
                      {states?.states.map((role, index) => (
                        <option key={role.id} value={role.id}>
                          {role.state_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="inputCity" class="form-label">City</label>
                    <select id="inputCity" class="form-select"  name='cities_state_id' value={formData.cities_state_id} onChange={handleChange}  >
                      <option selected>Choose...</option>
                      {cities && ( 
                        cities.cities.map((city, index) => ( 
                          <option key={city.id} value={city.id}>
                            {city.city}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                 
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary m-2">Submit</button>
                    <button type="reset" class="btn btn-secondary">Reset</button>
                  </div>
                </form>
                </div>
              </div>
            </div>
            </div>
          </section>
      </main>
      
    </div>
  );
};

export default User;