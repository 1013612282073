import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import Sidebar from '../common/Sidebar';
import Navigation from '../common/Navigation';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
const InspectionData = () => {
  
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [inspectionId, setInspectionId] = useState(null);
  const [list, setList] = useState([]); 

  const fetchInfo = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    setInspectionId(id); // Update LandId state using the id from URL params
    try {
      const token = localStorage.getItem('cas');
      if (!token) {
        throw new Error('Missing authentication token in local storage.');
      }

      const response = await axios.get(
        `http://122.160.30.162/cas/api/admin/inspection?id=${id}`,
        {
          headers: {
            'x-authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

    setList(response.data)
    
  } catch (error) {
      // Handle API errors gracefully (e.g., display error message to user)
      console.error('Error fetching plot coordinates:', error);
      setError('Failed to retrieve plot coordinates.'); // User-friendly message
    } finally {
      // Optionally, you could perform actions like pausing a loader here
    }
  };
  useEffect(()=>{
    fetchInfo()
  },[])
  return (
    <div>
      <Navigation />
      <Sidebar />
      {error && <p>Error: {error}</p>}
      <main id="main" className="main">

      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

          <li className="breadcrumb-item active">
            <Link to={'/inspection-details' }>
            Inspections 
            </Link>  
          </li>
          <li className="breadcrumb-item active">
            Inspection details
          </li>
          </ol>
        </nav>
      </div>
      {console.log(list.data)}

        <div className="row  d-flex justify-content-center">
        {
          list.data && list.data.length > 0 && list.data.map((item) => (
           
            <div className="col-12 col-lg-12" key={item.id} >
              <div className="card">
                  <div className="card-body profile-card pt-4 ">
                  <div className='row'>
                    <div className='col-2'>
                      <b>Plot Khasra :</b> {item.plot.slug}
                    </div>
                    <div className='col-2'>
                      <b> Crop Type:</b> {item.crop_type}
                    </div>
                    <div className='col-2'>
                      <b> Crop :</b> {item.crop}
                    </div>
                    <div className='col-2'>
                    <b> Status : <span className='text-success'>{item.status} <i className="bi bi-bullseye"></i></span></b> 
                    </div>
                    <div className='col-3'>
                      <b> Inspection On :</b> {item.created_at}
                      <div>
                        <b> View map :</b>
                        <Link className="badge bg-primary text-light" to={'/inspection?id='+ item.id}>
                          <i className="bi bi-toggle-on me-1"></i>Map
                        </Link>
                      </div> 
                    </div>
                  </div>
                  </div>
                </div>
              <div className="card">
                  <div className="card-body profile-card  ">
                    {/* <div className='row'>
                      <div className='col-3'>
                        <label htmlFor="inputState" className="form-label">Select Attribute Type</label>
                        <select id="inputState" className="form-select">
                        <option value="">Choose...</option>
                        <option value="owned">Season</option>
                        <option value="rented">Water Requirement</option>
                        <option value="rented">Region</option>
                        <option value="owned">Sunlight Need</option>
                        <option value="rented">Sub-Season (for Season)</option>
                        <option value="rented">Pest Resistance</option>
                        </select>
                      
                      </div>
                      <div className='col-3'>
                        <label htmlFor="inputState" className="form-label ">Select Attribute Value</label>
                        <select id="inputState" className="form-select">
                        <option value="">Choose...</option>
                        <option value="owned">Kharif (Summer)</option>
                        <option value="rented">High (Water Requirement)</option>
                        <option value="rented">Tropical (Region)</option>
                        <option value="owned">Full Sun (Sunlight Need)</option>
                        <option value="rented">Rabi (Winter)</option>
                        <option value="rented">Moderate</option>
                        <option value="owned">Moderate</option>
                        <option value="rented">Temperate/Tropical</option>
                        <option value="rented">Low (Sunlight Need)</option>
                        
                        </select>
                      
                      </div>
                  
                    </div> */}
                    <div className='row'>
                      <div className='col-3 '>Attributes </div>
                      <div className='col-3'>Value</div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>Season :</div>
                      <div className='col-3'>Winter</div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>Water Requirement :</div>
                      <div className='col-3'>High (*** ltr)</div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>Region:</div>
                      <div className='col-3'>Tropical</div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>Sunlight Need : </div>
                      <div className='col-3'>Moderate</div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>Pest Resistance : </div>
                      <div className='col-3'>Moderate</div>
                    </div>
                  </div>
              </div>
              
              
              {/* <div className="card">
                <div className="card-body profile-card ">
                  <div className='row text-center'>
                    <div className='col-3'>
                      Upload Image (Files) : <button type="button" className="btn btn-info text-light"><i className="bi bi-folder"></i></button>
                    </div>
                    <div className='col-3'>
                      
                    </div>
                  </div>
                  <div className='mt-3 row text-center'>
                    <div className='col-3'>
                        <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                        <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                    <div className='col-3'>
                      <img src="https://dummyimage.com/200x200/9495a8/9495a8.png&text=200*200" className="img-thumbnail" alt="..." />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          
          ))}
        
      
      

        </div>
    </main>
    </div>
  );
};

export default InspectionData;
